import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    return (
        <div className="roboto-regular container-md App pt-5">
            <div className="row">
                <div className="col-12 text-center">
                    <h2>Tomasz Maj IT services</h2>
                </div>
            </div>
            <div className="row d-flex justify-content-center pt-3">
                <div className="col-8">
                    <p>
                        Passionately developing and maintaining solutions for 20+ years. I'm highly engaged into my
                        tasks,
                        constantly thinking about improvements.
                    </p>
                    <p>
                        Development of <a href="https://en.wikipedia.org/wiki/ARPANET">ARPANET</a> sparked my interest
                        in <a href="http://web.archive.org/web/20040828215550/http://kecz4p.prv.pl/" target="_blank" rel="noreferrer">creating
                        dynamic, widely available web pages</a>. During further years this passion led me deeper into web
                        development
                        and also spread to other ideas, like every-day task automation scripts or first attempts at
                        hacking.
                    </p>
                    <p>
                        My professional experience started with fairly robust - at that time - javascript and php with
                        combination
                        of sql. Soon I realised that to reach enterprise levels of maturity, I need to pick enterprise
                        level
                        language, which Java is ideal for. This exposed me to numerous implementations of common design
                        patterns,
                        importance of test
                        first approach, how to properly structure the code for it to be readable and thus maintainable.
                        Every day collaboration showed me that the greatest software comes from hands of many.
                        In further years I was aiming to place myself in more product oriented positions where I would
                        have more
                        impact on deliverables, sometimes leading features from story definition till production release
                        and
                        monitoring afterwards. I've also learned how to lead a team of few. Recent years accommodated me
                        with Go,
                        Ruby and Python. In every single project there was always something in React, effectively
                        extending the
                        range to full-stack development.
                    </p>
                    <p>
                        One of my biggest interests is IT security. It was always rewarding to see that I'm able to
                        influence
                        someone's software from external. Some of development choices can even lead to easy access to
                        de-compiled
                        source code, leaving it vulnerable to modifications. I always keep those attack vectors in my
                        head whenever
                        developing any new solution.
                    </p>
                    <p>
                        I'm constantly chasing progress on AI as I think it's revolutionizing the
                        world. My curiosity led me to experiences with yet another language: Rust.
                    </p>
                    <p>
                        Feel free to contact me via <a href="https://linkedin.com/in/tomasz-maj-e-maj">LinkedIn
                        profile</a>.
                    </p>
                    <p>
                        Have a look at my <a href="https://github.com/tmaj87">GitHub account</a>.
                    </p>
                </div>
            </div>
            <div className="row pb-4">
                <div className="col-12 text-center pt-5">All rights reserved Tomasz Maj 2024</div>
            </div>
        </div>
    );
}

export default App;
